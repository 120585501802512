import AxiosLib from "@/apis/Axios/AxiosLib";
import ApisLang from "@/locales/Apis/ApisLang";

const url = 'AgentApi/User.';
const apis = {
    ...AxiosLib,

    /**
     * 获取用户列表
     * @param userDataType 1=包含下级代理用户 2=只显示自己用户
     * @return {*}
     */
    getUserList(userDataType){
        return AxiosLib.post(`${url}UserData/getUserList`,{userDataType},true,false);
    },

    /**
     * 获取用户信息
     * @param userId
     * @return {*}
     */
    getUserData(userId){
        let fieldCodeLang = ApisLang('User','getUserData');
        return AxiosLib.post(
            `${url}UserData/getUserData`,{userId},
            true,false,
            fieldCodeLang,
        );
    },

    /**
     * 修改用户信息
     * @param userId
     * @param setInfo Json格式,允许属性:[password,name,status,nickname,contact]
     * @return {*}
     */
    setUserInfo(userId,setInfo){
        let fieldCodeLang = ApisLang('User','setUserInfo');
        return AxiosLib.post(
            `${url}UserData/setUserInfo`,
            {userId,setInfo},
            true,true,
            fieldCodeLang,
        );
    },

    /**
     * 回收用户
     * @param userList userList
     * @return {*}
     */
    batchRecovery(userList){
        let fieldCodeLang = ApisLang('User','batchRecovery');
        return AxiosLib.post(
            `${url}Recovery/batchRecovery`,
            {userList},
            true,true,
            fieldCodeLang,
        );
    },

    /**
     * 回收用户
     * @param userList userList
     * @return {*}
     */
    MoveUser(newAgent,userList){
        let fieldCodeLang = ApisLang('User','MoveUser');
        return AxiosLib.post(
            `${url}UserMove/MoveUser`,
            {newAgent,userList},
            true,true,
            fieldCodeLang,
        );
    },

    /**
     * 批量充值
     * @param list [充值列表]格式:ghaith001----100,relax01----300
     * @return {*}
     */
    batchUserMoney(list){
        let fieldCodeLang = ApisLang('User','batchUserMoney');
        return AxiosLib.post(
            `${url}Money/batchUserMoney`,
            {list},
            true,true,
            fieldCodeLang,
        );
    },

    //批量注册日志
    getLog(){
        return AxiosLib.post(
            `${url}Register/getLog`,
            {},
            true,false,
        );
    },

    //[批量注册日志]下载日志
    getFile(file){
        let fieldCodeLang = ApisLang('User','getFile');
        return AxiosLib.post(
            `${url}Register/getFile`,
            {file},
            true,false,
            fieldCodeLang,
        );
    },

    //批量注册
    userBulkRegister(postData){
        let fieldCodeLang = ApisLang('User','userBulkRegister');
        return AxiosLib.post(
            `${url}Register/userBulkRegister`,
            postData,
            true,false,
            fieldCodeLang,
        );
    },

    //[充值/回收]用户余额
    changeMoney(postData){
        let fieldCodeLang = ApisLang('User','changeMoney');
        return AxiosLib.post(
            `${url}Money/changeMoney`,
            postData,
            true,true,
            fieldCodeLang,
        );
    },

    //获取用户切割记录总数
    getUserCount(username){
        return AxiosLib.post(
            `${url}DownLoad/getUserCount`,
            {username},
            true,false,
        );
    },

    /**
     *
     * @param username
     * @param page
     * @param listRows
     * @returns {*}
     */
    getUserDownLoad(username,page,listRows){
        return AxiosLib.post(
            `${url}DownLoad/getUserDownLoad`,
            {username,page,listRows},
            true,false,
        );
    }


};
export default apis;