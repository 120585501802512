import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "RecoveryUserView"
};
const _hoisted_2 = {
  class: "RecoveryUserForm"
};
import HeaderNavBar from "@/components/Header/HeaderNavBar";
import VantMsgLib from "@/utils/Vant/VantMsgLib";
import Apis_User from "@/apis/Apis_User";
import ApisLang from "@/locales/Apis/ApisLang";
import JumpResultPage from "@/utils/Components/JumpResultPage";
import InputText from "@/components/Form/InputText";
export default {
  __name: 'RecoveryUser',
  setup(__props) {
    const InputTextOptions = {
      CacheDataField: 'UserList',
      //缓存数据字段
      Name: '回收用户' //[功能名字]用于提示用户是否执行该功能
    };

    const onSubmit = options => {
      let arr = options.arr || [];
      let str = arr.toString();
      Apis_User.batchRecovery(str).then(res => {
        if (!Apis_User.checkResCode(res)) {
          return;
        }
        let resArr = res.result;
        if (!Array.isArray(resArr)) {
          VantMsgLib.alert('执行出现异常,请联系技术人员');
          return;
        }
        let fieldCodeLang = ApisLang('User', 'batchRecovery_state');
        arr = resArr.map(item => {
          return {
            ...item,
            msg: fieldCodeLang[Number(item.state)] || '!unknown'
          };
        });
        JumpResultPage(arr);
      });
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_unref(HeaderNavBar), {
        title: "回收用户"
      }), _createElementVNode("div", _hoisted_2, [_createVNode(_unref(InputText), {
        Options: InputTextOptions,
        onOnSubmit: onSubmit
      })])]);
    };
  }
};