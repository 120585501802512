import CacheData from "@/utils/Cache/CacheData";
import Common_Router from "@/utils/Common/Common_Router";

/**
 * 跳转到结果页面
 * @param objArr [数组]对象 (id,object[目标],state[状态码],str[结果文本])
 * @constructor
 */
const JumpResultPage = (objArr) => {
    CacheData.SaveData('ResultPage',objArr);
    Common_Router.push({name:'Common.Result'})
}
export default JumpResultPage